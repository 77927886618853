import { Injectable } from '@angular/core';

const TITLE_TAGS: string[] = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

@Injectable({
  providedIn: 'root'
})
export class FaqSchemaService {

  /**
   * Dom parser for use some DOM Api
   * @private
   */
  private _parser: DOMParser;

  /**
   * All content elements
   * @private
   */
  private _contentElements: ChildNode[];

  /**
   * Chunks elements for _contentElements
   * @private
   */
  private _chunksElements: any[] = [];

  /**
   * Title indexes for create _chunksElements
   * @private
   */
  private _titleIndexes: number[] = [];

  /**
   * Element for save result and return innerHTML
   * @private
   */
  private _resultHTML: HTMLDivElement;

  constructor() {
  }

  /**
   * Parse content and add fqg schema
   * @param content
   */
  public addFaqSchema(content: string): string {
    this._renewInstances();
    this._parser = new DOMParser();
    const contentHTML = this._parser.parseFromString(content, 'text/html').body;
    this._contentElements = Array.from(contentHTML.childNodes);
    this._resultHTML = document.createElement('div');
    this._pushTitleIndexes();
    this._createChuncksElements();
    this._createParentDivsForAnswerText();
    this._createParentAndAddAttrs();

    return this._resultHTML.innerHTML;
  }

  /**
   * Push indexes title for create chunks
   * @private
   */
  private _pushTitleIndexes() {
    this._contentElements.forEach((el: HTMLElement, i) => {
      if (el && el.tagName && TITLE_TAGS.includes(el.tagName)) {
        el.setAttribute('itemprop', 'name');
        this._titleIndexes.push(i);
      }
    })
  }

  /**
   * Create chunks from _titleIndexes
   * @private
   */
  private _createChuncksElements() {
    if (this._titleIndexes.length > 0) {
      this._titleIndexes.forEach((num, i, arr) => {
        if (i === arr.length - 1) {
          this._chunksElements.push(this._contentElements.slice(num));
        } else {
          this._chunksElements.push(this._contentElements.slice(num, arr[i + 1]));
        }
      });
    }
  }

  private _createParentDivsForAnswerText() {
    this._chunksElements = this._chunksElements.map(chunk => {
      if (Array.isArray(chunk)) {
        return [
          chunk[0],
          this._createTextSchema(chunk.slice(1))
        ];
      }
    });
  }

  /**
   * Create parent element and add element from chunk
   * @private
   */
  private _createParentAndAddAttrs() {
    if (this._chunksElements.length > 0) {
      this._chunksElements.forEach((chunk: ChildNode[]) => {
        const parentDiv = this._createParentSchema();
        if (Array.isArray(chunk)) {
          parentDiv.append(...chunk);
        }

        this._resultHTML.append(parentDiv);
      });
    }
  }

  /**
   * Create parent element and add schema attributes
   * @private
   */
  private _createParentSchema(): HTMLDivElement {
    const div = document.createElement('div');
    div.setAttribute('itemscope', '');
    div.setAttribute('itemprop', 'mainEntity');
    div.setAttribute('itemtype', 'https://schema.org/Question');
    return div;
  }

  /**
   * Create text schema element and add schema attributes
   * @private
   */
  private _createTextSchema(partChunks: ChildNode[]): HTMLElement {
    const parentAnswer = document.createElement('div');
    parentAnswer.setAttribute('itemscope', '');
    parentAnswer.setAttribute('itemprop', 'acceptedAnswer');
    parentAnswer.setAttribute('itemtype', 'https://schema.org/Answer');

    const textAnswer = document.createElement('div');
    textAnswer.setAttribute('itemprop', 'text');

    textAnswer.append(...partChunks);
    parentAnswer.appendChild(textAnswer);

    return parentAnswer;
  }

  /**
   * Renew all instances before use
   * @private
   */
  private _renewInstances() {
    this._resultHTML = null;
    this._contentElements = null;
    this._parser = null;
    this._chunksElements = [];
    this._titleIndexes = [];
  }
}
