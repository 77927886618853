import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, shareReplay, tap, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { LanguageService } from './language/language.service';
import { DeviceDetectorService } from 'ngx-unificator/services';
import { PlatformService } from './platform.service';
import { isBot } from '../helpers/bot';
import { RedirectorService } from './redirector.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private _isSignupRestricted = false;

  /**
   * Use for hide sign up
   * @private
   */
  private _isSignupRestricted$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  /**
   * Environment observable
   */
  private _environment$: Observable<any> = this._api.serviceEnvironment().pipe(
    switchMap(e => !e ? throwError({error: {error: {stack: 'SyntaxError'}}}) : of(e)),
    catchError(error => {
      this._redirector.checkError(error);
      return of({
        data: {
          cacheControl: [],
          languageList: [],
          countryList: [],
          country: {},
          locale: {
            short: 'en'
          }
        }
      });
    }),
    map((response: any) => {
      response.data.languageList = (response.data.languageList || []).map(lang => {
        lang.short = this._lang.normalizeLang(lang.short);
        return lang;
      });

      response.data.locale.short = this._lang.normalizeLang(response.data.locale.short);

      return {
        ...response
      };
    }),
    shareReplay(1),
    tap(response => {
      this._environment = response.data;
      this._checkRestrictCountry();
    })
  );

  /**
   * Environment object
   */
  private _environment;

  constructor(
    private _api: CmsApiService,
    private _router: Router,
    private _lang: LanguageService,
    private _platform: PlatformService,
    private _device: DeviceDetectorService,
    private _redirector: RedirectorService
  ) { }

  /**
   * Is user from Sweden
   */
  get isSe(): boolean {
    return this._environment
        && this._environment.country
        && this._environment.country.short === 'se';
  }

  /**
   * Is user from France
   */
  get isFr(): boolean {
    return this._environment?.country?.short === 'fr';
  }

  /**
   * Returns environment ReplaySubject
   */
  get env$(): Observable<any> {
    return this._environment$;
  }

  /**
   * Returns environment object
   */
  get env(): any {
    return this._environment;
  }

  get isSignupRestricted$(): ReplaySubject<boolean> {
    return this._isSignupRestricted$;
  }

  get isRestrictSignUp(): boolean {
    return this._isSignupRestricted;
  }

  set setIsSignupRestricted(value: boolean) {
    this._isSignupRestricted = value;
  }

  private _checkRestrictCountry() {
    if (this._platform.isBrowser && !isBot(this._device.userAgent) && this._environment.country.isRestricted) {
      setTimeout(() => {
        this._router.navigateByUrl('/403');
      }, 1000);
    }
  }
  /**
   * Returns true if user ip from provided countries
   *
   * @param countries
   */
  public isUserFrom(countries: string|string[]): boolean {
    if (!Array.isArray(countries)) {
      countries = [countries] as string[];
    }

    return this._environment
      && this._environment.country
      && countries.includes(this._environment.country.short);
  }

  /**
   * Returns user geo
   *
   */
  public getUserGeo() {
    return this._environment && this._environment.country;
  }
}
