import { CookieService } from 'ngx-unificator/services';
import { map, switchMap, catchError, first } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { RedirectorApiService } from './api/redirector-api.service';
import { of, Observable } from 'rxjs';
import { LanguageService } from './language/language.service';

interface Mirror {
  id: number;
  domain: string;
}

@Injectable({
  providedIn: 'root'
})
export class RedirectorService {

  /**
   * Block error name (status = 0)
   */
  private readonly _blockError: string = 'Unknown Error';

  /**
   * Default domain
   */
  private readonly _defaultDomain: string = this._window.location.hostname.includes('localhost') ? 'stage.wildfortune.io' : this._window.location.hostname;

  /**
   * Max request for get new mirror
   */
  private readonly MAX_MIRROR_REQUEST = 10;

  /**
   * Avaible mirror
   */
  private _avaibleMirror: Mirror = null;

  /**
   * Current domain offset
   */
  private _offset = 0;

  constructor(
    private _platform: PlatformService,
    private _api: RedirectorApiService,
    private _cookie: CookieService,
    private _lang: LanguageService,
    @Inject(WINDOW) private _window: Window,
  ) { }

  /**
   * Check if domain was blocked
   * @param error
   */
  public checkError(error) {
    if (this._platform.isBrowser && error) {
      if (this._checkResponseError(error)) {
        this.getMirror$().pipe(
          first(),
          map((mirror: Mirror) => {
            this._avaibleMirror = mirror;
            return mirror;
          }),
          switchMap(e => e ? this.checkNewMirror$() : of(null)),
        ).subscribe(e => {
          if (e) {
            this._window.localStorage.removeItem('saved-data');
            this._window.location.href = `https://${this._avaibleMirror.domain}/transfer?C=${JSON.stringify(this._cookie.getAll() || {})}&L=${JSON.stringify(this._window.localStorage)}&LANG=${this._lang.current}`;
          }
        });
      }
    }
  }

  /**
   * Load avaible mirror list
   * @returns
   */
  public getMirror$(): Observable<Mirror> {
    const offset = (((this._offset * 17) + 63) << 13) + 75;
    return this._api.getMirrorOnStage({domain: this._defaultDomain, b: offset}).pipe(
      map(res => res.data ? res.data[0] : null)
    );
  }

  /**
   * Check new domain\mirror
   * @param id
   * @returns
   */
   public checkNewMirror$() {
    return this._api.checkNewMirror(this._avaibleMirror.domain.replace('www.', 'api.')).pipe(
      first(),
      catchError(error => {
        this._offset++;
        if (this._offset <= this.MAX_MIRROR_REQUEST) {
          this.checkError(error);
        }
        return of(false);
      })
    );
  }

  /**
   * Block current domain\mirror
   * @param id
   * @returns
   */
   public blockMirror$(id) {
    this._resolveHeaders();
    return this._api.blockMirror(id).pipe(
      catchError(error => {
        return of(true);
      })
    );
  }

  /**
   * Resolve custom headers for request
   */
  private _resolveHeaders() {
    const UTS = Date.now();
    const ID = UTS >> (UTS % 10) >> (UTS % 3);
    this._cookie.set('ID', `${ID}`, 999, '/', this._window.location.hostname.split('.').slice(-2).join('.'));
    this._api.customHeadersList.push({key: 'UID', val: `${ID}`});
    this._api.customHeadersList.push({key: 'UTS', val: `${UTS}`});
  }

  /**
   * Check errors
   * If response 'Unknown Error' or response status 200 Ok but body = null or html
   * @param error
   * @returns
   */
  private _checkResponseError(error): boolean {
    if (this._window.navigator.onLine) {
      if (error.statusText === this._blockError) {
        return true;
      }
      if (error.error && error.error.error && error.error.error.stack && error.error.error.stack.includes('SyntaxError' || 'JSON at position')) {
        return true;
      }
    }
    return false;
  }

}
