import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {REQUEST} from '../../../express.tokens';
import {environment} from '../../../environments/environment';
import { PlatformService } from '../services/platform.service';
import {devDomain} from '../../../config/dev.domain';

@Injectable({
  providedIn: 'root'
})
export class HostRequestsInterceptor implements HttpInterceptor {

  private _tests = [
    ['https://api.wildfortune.io/api/en/banner/list?type=1', 'https://www.wild-fortune.io', 'https://api.wild-fortune.io/api/en/banner/list?type=1'],
    ['https://www.wildfortune.io/api/en/banner/list?type=1', 'https://localhost:4200', 'https://www.wildfortune.io/api/en/banner/list?type=1'],
    ['https://wildfortune.io/api/player/settings', 'https://www.wild-fortune.io', 'https://wild-fortune.io/api/player/settings'],

    ['https://stage.wildfortune.io/api/en/banner/list?type=1', 'https://stage.wildfortune.io', 'https://stage.wildfortune.io/api/en/banner/list?type=1'],
    ['https://stage.wild-fortune.io/api/en/banner/list?type=1', 'https://stage.wild-fortune.io', 'https://stage.wild-fortune.io/api/en/banner/list?type=1'],

    ['https://wildfortune.io/en-ca/games/playngo/BookofDead/62856', 'https://www.wildfortune1.io', 'https://wildfortune1.io/en-ca/games/playngo/BookofDead/62856'],
  ];

  /**
   * List of app hosts from which request urls should not be modified
   *
   * @private
   */
  private _ignoredHosts = [
    {
      host: 'https://localhost:4200',
      ignoredSS: true,
      ignoredCMS: true
    },
    {
      host: 'https://stage.wildfortune.io',
      ignoredSS: true,
      ignoredCMS: true
    },
    {
      host: 'https://dev.wildfortune.io',
      ignoredSS: true,
      ignoredCMS: true
    },
    {
      host: 'https://dev.wild-fortune.io',
      ignoredSS: true,
      ignoredCMS: true
    },
    {
      host: 'https://stage.wild-fortune.io',
      ignoredSS: false,
      ignoredCMS: false
    },
    ...devDomain.map(d => ({host: d, ignoredSS: true, ignoredCMS: true}))
  ];

  public appUrl = `${this._getAppHost().includes('localhost') ? 'http://' : 'https://'}${this._getAppHost()}`;

  constructor(
    private _platform: PlatformService,
    private _injector: Injector
  ) {
    // this._runTests();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appUrl = `https://${this._getAppHost()}`;

    req = req.clone({
      url: req.params.has('crossdomain') ? req.url : this.overrideUrl(req.url, appUrl)
    });

    return next.handle(req);
  }

  public getDomainName() {
    return this._getAppHost();
  }
  /**
   * Change provided url to depending on host url
   *
   * @param url
   * @param appUrl
   */
  public overrideUrl(url: string, appUrl: string = `https://${this._getAppHost()}`): string {
    switch (true) {
      case this._isIgnoredHostSS(appUrl) && this._isIgnoredHostCMS(appUrl): return url;
      case this._isIgnoredHostCMS(appUrl): return this._overrideSSHost(url, appUrl);
      case this._isIgnoredHostSS(appUrl): return this._overrideCmsHost(url, appUrl);
      case this._isRequestToCms(url): return this._overrideCmsHost(url, appUrl);
      case this._isRequestToMlh(url): return this._overrideMlhHost(url, appUrl, environment.mlh_host);
      case this._isRequestToSS(url): return this._overrideSSHost(url, appUrl);
      default: return url;
    }
  }

  /**
   * Returns hostname on which app running
   *
   * @private
   */
  private _getAppHost(): string {
    return this._platform.isBrowser
      ? window.location.host
      : this._injector.get(REQUEST).headers.host;
  }

  public overrideCmsImgUrl(url: string, appUrl: string = `https://${this._getAppHost()}`): string {
    switch (true) {
      case this._isIgnoredHostSS(appUrl):
        return url;
      case this._isRequestToCmsImg(url):
        return this._overrideCmsImgHost(url, appUrl, environment.assets_host);
      default:
        return url;
    }
  }

  private _overrideCmsImgHost(url: string, host: string, appHost: string): string {
    const regExp = new RegExp('wildfortune', 'g');
    const hostname = host.split('.')[1];

    return url && !host.includes('localhost') ? url.replace(regExp, hostname) : url;
  }

  private _isRequestToCmsImg(url: string): boolean {
    return url.startsWith(environment.assets_host);
  }

  /**
   * Override request to SS
   *
   * @private
   */
  private _overrideSSHost(url: string, host: string): string {
    return url.replace(environment.ss_host, host).replace('https://www.', 'https://').replace('https://stage.', 'https://');
  }

  /**
   * Override request to cms
   *
   * @private
   */
  private _overrideCmsHost(url: string, host: string): string {
    return url.replace(environment.cms_host, host).replace('https://www.', 'https://api.');
  }

  /**
   * Override request to mlh
   *
   * @private
   */
  private _overrideMlhHost(url: string, host: string, mlhHost: string): string {
    return url.replace(mlhHost, host).replace('https://www.', 'https://mlh.');
  }

  /**
   * Returns true if request url is CMS url
   *
   * @param url
   * @private
   */
  private _isRequestToCms(url: string): boolean {
    return url.startsWith(environment.cms_host);
  }

  /**
   * Returns true if request url is mlh url
   *
   * @param url
   * @private
   */
  private _isRequestToMlh(url: string): boolean {
    return url.startsWith(environment.mlh_host);
  }

  /**
   * Returns true if request url is SS url
   *
   * @param url
   * @private
   */
  private _isRequestToSS(url: string): boolean {
    return url.startsWith(environment.ss_host);
  }

  /**
   * Returns true if url should not be override for SS
   *
   * @param host
   * @private
   */
  private _isIgnoredHostSS(host: string): boolean {
    if (host.includes('localhost') || host.includes('127.0.0.1')) {
      return true;
    }
    return this._ignoredHosts.some(h => h.host.includes(host)) ? this._ignoredHosts.find(h => h.host.includes(host)).ignoredSS : false;
  }

  /**
   * Returns true if url should not be override for CMS
   *
   * @param host
   * @private
   */
  private _isIgnoredHostCMS(host: string): boolean {
    if (host.includes('localhost') || host.includes('127.0.0.1')) {
      return true;
    }
    return this._ignoredHosts.some(h => h.host.includes(host)) ? this._ignoredHosts.find(h => h.host.includes(host)).ignoredCMS : false;
  }

  /**
   * Run table tests
   *
   * @private
   */
  private _runTests() {
    this._tests.forEach((test, i) => {
      const url = this.overrideUrl(test[0], test[1]);

      console.log(`Test ${i}: `, url === test[2], url);
    });
  }
}
